import cx from "classnames";
import { motion } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { CommonTranslation, useCommonTranslations } from "../components/CommonTranslations";
import { useCast } from "../components/Homepage/HomepageCast";
import { useLocale, useQueryString } from "../components/LanguageMenu";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FacebookIcon from "../images/facebook.svg";
import InstagramIcon from "../images/instagram.svg";
import StarIcon from "../images/star.svg";
import TikTokIcon from "../images/tiktok.svg";
import TwitterIcon from "../images/twitter.svg";
import ImdbIcon from "../images/icons/imdb.png";

export default function Cast({ location }: { location: Location }) {
  const commonTranslations = useCommonTranslations();

  return (
    <Layout location={location} headerProps={{ fixed: true, filled: true, logo: true }}>
      <Seo title={commonTranslations.cast} />
      <Body location={location} />
    </Layout>
  );
}

const Body = ({ location }: { location: Location }) => {
  const actors = useCast();
  const [selectedActor, setSelectedActor] = useQueryString("actor", "");

  useEffect(() => {
    if (!location) return;

    const params = new URLSearchParams(location.search);
    const name = params.get("actor");
    if (!name || name === selectedActor) return;

    setSelectedActor(actors.find(({ realName }) => realName === name)?.realName || "");
  }, [location]);

  const onChangeActor = (actor: string) => {
    setSelectedActor(actor);
    document.getElementById("cast")?.scrollIntoView();
  };

  const currentActor =
    useMemo(() => actors.find(({ realName }) => realName === selectedActor), [selectedActor]) || actors[0];

  return (
    <div className="min-h-screen pb-16">
      <div className="grid grid-cols-12 pt-20 text-white">
        <div className="col-span-10 col-start-2">
          <Actors actors={actors} value={currentActor?.realName} onChange={onChangeActor} />
        </div>
        <div id="cast" className="col-span-10 col-start-2 grid grid-cols-12 mt-14">
          <div className="col-span-12 mb-8 text-3xl order-1">
            <span className="font-bold">{currentActor.realName}</span>{" "}
            <span className="font-light">{currentActor.characterName}</span>
          </div>
          <div className="col-span-12 md:col-span-8 mb-8 text-xl md:pr-10 flex flex-col order-3 md:order-2">
            {currentActor.paragraphs?.map(({ text }, i) => (
              <p key={i} className="mt-4 text-sm md:mt-0 md:mb-8">
                {text}
              </p>
            ))}
            <div className="text-xl mt-8">
              {currentActor.links?.map(({ text, url }, i) => (
                <a key={i} className="text-sm md:text-base mb-2 flex items-center space-x-2 underline" href={url}>
                  <img src={StarIcon} alt="star icon" />
                  <span>{text}</span>
                </a>
              ))}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4 md:order-3 order-2">
            <GatsbyImage image={currentActor.url} alt={currentActor.realName} />
            <div className="mt-3 md:mt-8">
              <ActorSocials socials={currentActor.socials} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ActorSocials = ({ socials }: { socials?: Socials }) => {
  if (!socials) return null;
  return (
    <div className="flex space-x-4 text-white">
      {socials.facebook && (
        <a href={socials.facebook} target="_blank">
          <img src={FacebookIcon} alt="facebook" />
        </a>
      )}
      {socials.twitter && (
        <a href={socials.twitter} target="_blank">
          <img src={TwitterIcon} alt="twitter" />
        </a>
      )}
      {socials.instagram && (
        <a href={socials.instagram} target="_blank">
          <img src={InstagramIcon} alt="instagram" />
        </a>
      )}
      {socials.tiktok && (
        <a href={socials.tiktok} target="_blank">
          <img src={TikTokIcon} alt="tiktok" />
        </a>
      )}
      {socials.imdb && (
        <a href={socials.imdb} target="_blank">
          <img className="w-6 h-6" src={ImdbIcon} alt="imdb" />
        </a>
      )}
    </div>
  );
};

const imageVariants = {
  rest: {
    opacity: 0.6,
    transition: { duration: 0.1 },
  },
  hover: {
    scale: 1.075,
    opacity: 1,
    transition: { duration: 0.4 },
  },
};

const Actors = ({ actors, value, onChange }: { actors: any[]; value: string; onChange?(realName: string): void }) => {
  return (
    <div className="text-white">
      <div className="text-xl md:text-3xl font-bold mb-8">
        <CommonTranslation value="meetTheCast" />
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6">
        {actors.map(({ realName, characterName, url }, index) => (
          <div key={realName}>
            <button className="block text-left" onClick={() => onChange?.(realName)}>
              <motion.div className="mb-8 lg:mb-0 mx-2" whileHover="hover" initial="rest">
                <motion.div
                  className={cx("overflow-hidden rounded-xl", {
                    "border border-[#B4B4B4]": value === realName,
                  })}
                >
                  <motion.div variants={imageVariants}>
                    <GatsbyImage alt={realName} image={url} />
                  </motion.div>
                </motion.div>
                <div className="mt-4">
                  <div className="text-xs md:text-lg font-bold">{realName}</div>
                  <div className="text-xs md:text-base font-light mt-2">{characterName}</div>
                </div>
              </motion.div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
